import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Typography from '@material-ui/core/Typography';

const fondoImagenUrl = require("../images/productos_apple.png");
const iconoCentral = require("../images/icono_central.png");

const styles = theme => ({
    tituloPrincipal1: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "48px",
        lineHeight: "53px",
        color: "#0087C2",
        marginBottom: "1rem"
    },
    tituloPrincipal2: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "32px",
        lineHeight: "53px",
        color: "#0087C2",
        marginBottom: "3rem"
    },
    textoDetalle: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "26px",
        color: "#474747",
        marginBottom: "1.5rem"
    },
    iconoCentral: {
        width: '200px',
        marginLeft: '-100px',
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    fondoImagen: {
        background: `url(${fondoImagenUrl}) no-repeat`,
        backgroundSize: "cover",
        height: '960px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    containerTab: {
        padding: "10px 7rem 10px 5rem", 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "flex-start",
        [theme.breakpoints.down("sm")]: {
          padding: "10px 1rem 10px 1rem", 
        }
    }
})
const queryString = require('query-string');
class LandingExito extends Component{
    constructor(props){
        super(props);
        this.state = {
            plan: ''
        }
    }
    toRetry(){

    }
    componentDidMount(){
        
        var parsed = queryString.parse(this.props.location.search);
        this.setState({plan: parsed.plan ? parsed.plan : ''});
        console.log(document.querySelector('.mp-mercadopago-checkout-wrapper'));
        if(document.querySelector('.mp-mercadopago-checkout-wrapper')){
            while(document.querySelector('.mp-mercadopago-checkout-wrapper')){
                document.querySelector('.mp-mercadopago-checkout-wrapper').remove();
            }
        }
    }
    render(){
        const { classes } = this.props;
        return (
            <>
                <Topbar />
                    <Grid container spacing={0}>
                        <Grid  item xs={12} sm={12} md={6}>
                            <div className={classes.containerTab}>
                                <Typography style={{marginTop: "2rem"}} className={classes.tituloPrincipal1}>
                                    Compra exitosa!
                                </Typography>
                                <Typography className={classes.tituloPrincipal2}>
                                    Ya tienes tu producto asegurado.
                                </Typography>
                                <Typography  className={classes.textoDetalle}>
                                    Recibirás en tu correo una copia de {this.state.plan.toLowerCase().includes('extendida') ? 'tu certificado de garantía extendida' : 'la póliza de tu seguro contra robos'}.
                                    <br></br>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className={classes.fondoImagen } item xs={12} sm={12} md={6}>
                            <img className={classes.iconoCentral} src={iconoCentral} alt="icono"></img>
                        </Grid>
                    </Grid>
                <FooterGarnet />
            </>
        );
    }
}

export default withRouter(withStyles(styles)(LandingExito));
