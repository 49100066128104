import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";

const fondoImagenUrl = require("../images/productos_apple.png");
const iconoCentral = require("../images/icono_central.png");

const styles = theme => ({
    tituloPrincipal1: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "48px",
        lineHeight: "53px",
        color: "#0087C2",
        marginBottom: "1rem"
    },
    tituloPrincipal2: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "32px",
        lineHeight: "53px",
        color: "#0087C2",
        marginBottom: "3rem"
    },
    textoDetalle: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "26px",
        color: "#474747",
        marginBottom: "1.5rem"
    },
    iconoCentral: {
        width: '200px',
        marginLeft: '-100px',
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    fondoImagen: {
        background: `url(${fondoImagenUrl}) no-repeat`,
        backgroundSize: "cover",
        height: '960px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    containerTab: {
        padding: "10px 7rem 10px 5rem", 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "flex-start",
        [theme.breakpoints.down("sm")]: {
          padding: "10px 1rem 10px 1rem", 
        }
    }
})

class LandingError extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        console.log('preference_id from props', this.props.preference_id);
        if(document.querySelector('.mp-mercadopago-checkout-wrapper')){
            document.querySelector('.mp-mercadopago-checkout-wrapper').remove();
        }
    }
    async toRetry(){
        if(this.props.preference_id){
            await global.config.mercadopago.checkout({
                preference: {
                    id: this.props.preference_id
                },
                theme: {
                    elementsColor: '#0087C2',
                    headerColor: '#2D2D2D',
                },
                autoOpen: true,
            });
        } 
    }
    render(){
        const { classes } = this.props;
        return (
            <>
            <Topbar />
            <Grid container spacing={0}>
                <Grid  item xs={12} sm={12} md={6}>
                    <div className={classes.containerTab}>
                        <Typography style={{marginTop: "2rem"}} className={classes.tituloPrincipal1}>
                            Ups!
                        </Typography>
                        <Typography className={classes.tituloPrincipal2}>
                            Ha ocurrido un error
                        </Typography>
                        <Typography  className={classes.textoDetalle}>
                            Intentar nuevamente el pago de tu seguro haciendo click aquí:
                        </Typography>
                        <Button onClick={() => this.toRetry()} color="primary" style={{fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "24px", textTransform: "capitalize", marginBottom: "2rem", alignSelf: "stretch", maxWidth: "450px", padding: "1rem 0rem", marginTop: "2rem"}} variant="contained" disabled={false} >
                            Pagar
                        </Button>
                    </div>
                </Grid>
                <Grid className={classes.fondoImagen } item xs={12} sm={12} md={6}>
                    <img className={classes.iconoCentral} src={iconoCentral} alt="icono"></img>
                </Grid>
            </Grid>
            <FooterGarnet />
            </>
        );
    }
}
const  mapStateToProps = (state) => ({
    preference_id: state.preference_id
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(LandingError)));
